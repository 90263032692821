.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.flexGrow {
  flex-grow:1;
  display:'flex';
}
.flexRow {
  display:flex;
  flex-direction:row;
}
.flexColumn {
  display:flex;
  flex-direction:column;
  flex-grow:1;
}
.flexGrowScroll {
  display:flex;
  flex-grow:1;
  flex-direction:column;
  overflow-y:scroll;
  scroll-behavior:smooth;
}
.flexScroll {
  display:flex;
  flex-direction:column;
  overflow-y:scroll;
  scroll-behavior:smooth;
}
html, body {margin: 0; height: 100%; overflow: hidden}
.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
}
.headerText {
  font-size:26px;
}
.menuDiv {
  font-size:22px;
  border:1px solid lightslategray;
  padding:5px;
  margin-right:7px;
  border-radius:10px;
  cursor:pointer;
}
.activeMenu {
  background-color:#549c82;
  color:white;
}
.sticky-table-cell { /* for react-sticky-table*/
  padding:4px !important;
}
.fullImage {
    object-fit: contain;
    max-width: 100%;
    width: auto;
}
.ewtIcon {
  cursor:pointer;
  font-size: 26px;
}
.blue {
  color:dodgerblue;
}